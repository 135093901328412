<template>
  <!-- Table Container Card -->
  <b-overlay :show="loading" rounded="sm" no-fade>
    <div v-if="quoteData != null" class="mt-1">
      <b-row v-if="quoteData != null">
        <!-- <b-col cols="6" md="4" class="text-left">
          <h5 v-if="quoteData.customerResponse == null && process == 'draft'" style="border:1px solid gray; font-weight:bold; padding:5px; text-align: center;">Quote has not been generated yet.</h5>
          <h5 v-if="quoteData.customerResponse == 'draft' && process == 'quote'" style="border:1px solid gray; font-weight:bold; padding:5px; text-align: center;">Quote awaiting customer response</h5>
          <h5 v-if="quoteData.customerResponse == 'approve'" style="border:1px solid gray; font-weight:bold; padding:5px; text-align: center;">Customer has approved the quote.</h5>
          <h5 v-if="quoteData.customerResponse == 'reject'" style="border:1px solid gray; font-weight:bold; padding:5px; text-align: center;">Customer has rejected the quote.</h5>
          <h5 v-if="quoteData.customerResponse == 'call'" style="border:1px solid gray; font-weight:bold; padding:5px; text-align: center;">Customer has requested a call.</h5>
        </b-col> -->
        <b-col cols="5" md="3" class="text-center">
          <div style="border:1px solid gray; padding:3px;" class="d-flex">
            <div class="mx-auto">
              <h5 v-if="(quoteData.customerResponse == null || quoteData.customerResponse == 'draft') && process == 'draft'" style="font-weight:bold;">
                Draft
              </h5>
              <h5 v-if="quoteData.customerResponse == 'draft' && process == 'quote'" style="font-weight:bold;">
                Generated
              </h5>
              <h5 v-if="quoteData.customerResponse == 'mailed'" style="font-weight:bold;">
                Mailed
              </h5>
              <h5 v-if="quoteData.customerResponse == 'approve'" style="font-weight:bold;">
                Approved
              </h5>
              <h5 v-if="quoteData.customerResponse == 'reject'" style="font-weight:bold;">
                Rejected
              </h5>
              <h5 v-if="quoteData.customerResponse == 'call'" style="font-weight:bold;">
                Call Requested
              </h5>
            </div>

            <div class="text-right mr-2">
              <feather-icon icon="MailIcon" size="20" v-if="quoteData.mailSent == 1" class="cursor-pointer text-success" v-b-tooltip.hover :title="mailedLog" />
              <feather-icon icon="MailIcon" size="20" v-else class="cursor-pointer" v-b-tooltip.hover title="Quote has not been emailed to the customer yet." />
            </div>
          </div>
        </b-col>

        <b-col cols="7" md="9" class="text-right">
          <b-button variant="success" v-if="process != 'shop' && process != 'draft' && quoteData.customerResponse != 'approve'" class="mr-25" @click="customerStatus('approve')">Customer Approved</b-button>
          <b-button variant="danger" v-if="process != 'shop' && process != 'draft' && quoteData.customerResponse != 'reject'" class="mr-25" @click="customerStatus('reject')">Customer Rejected</b-button>
          <b-button variant="outline-danger" v-if="process != 'shop' && process != 'draft' && quoteData.customerResponse != 'call'" class="mr-25" @click="customerStatus('call')">Call Requested</b-button>
          <!-- 30-05 COMMENT -->
          <!-- <b-button variant="outline-secondary" v-if="process != 'draft'" class="mr-25" @click="mailQuote()">Send Quote </b-button> -->
          <b-button variant="secondary" v-if="process != 'draft'" @click="downloadQuote()">Download </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col> </b-col>
      </b-row>
    </div>
    <div>
      <b-card v-if="quoteData.projectId != null" class="mt-25">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-center mb-25">
            <h5 style="font-size: 1.75rem; font-weight: bolder;">Quotation</h5>
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="12" lg="9" xl="9"> </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3" class="text-right">
            <p><strong> Quote Number:</strong> #Q-{{ quoteData.projectId }}</p>
          </b-col>

          <!-- <b-col cols="6" class="text-right"> <img src="/logo.png" height="70rem" alt="" srcset="" /> </b-col> -->
        </b-row>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3" class="text-left">
            <strong> Quote Date:</strong>
            <flat-pickr
              placeholder="Select Quote Date"
              :disabled="quoteData.customerResponse == 'approve'"
              :style="quoteData.customerResponse == 'approve' ? '' : 'background-color:white'"
              v-model="quoteData.date"
              style="cursor:pointer"
              class="pl-1 form-control"
              :config="{ dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1 } }"
            />
            <!-- <b-form-datepicker :disabled="quoteData.customerResponse == 'approve'" :start-weekday="1" v-model="quoteData.date" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :hide-header="true" local="en-US" class="mb-1" today-variant="danger" /> -->
          </b-col>
        </b-row>

        <b-row class="mb-1">
          <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
            <strong> Expiry Date:</strong>
            <flat-pickr
              placeholder="Select Expiry Date"
              :disabled="quoteData.customerResponse == 'approve'"
              :style="quoteData.customerResponse == 'approve' ? '' : 'background-color:white'"
              v-model="quoteData.expiryDate"
              style="cursor:pointer"
              class="pl-1 form-control"
              :config="{ dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1 } }"
            />

            <!-- <b-form-datepicker
              :disabled="quoteData.customerResponse == 'approve'"
              :start-weekday="1"
              v-model="quoteData.expiryDate"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              :hide-header="true"
              local="en-US"
              class="mb-2"
              today-variant="danger"
            /> -->
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <table class="table table-sm" v-for="(subItem, index) in projectValues" :key="index">
              <input :ref="`refInputEl_${index}`" class="d-none" type="file" accept="image/*" @input="imageSelected" />
              <item-cropper v-if="selectedImage" :image="selectedImage" @cropped="imageCropped" />

              <thead>
                <tr>
                  <th colspan="9">
                    <span>{{ subItem.item }} {{ subItem.material ? '(' + subItem.material + ')' : '' }}</span>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th scope="col" style="width:11%" class="text-dark bg-light">
                    Labor
                  </th>
                  <th scope="col" style="width:11%" class="text-dark bg-light">Hours (<span style="font-size:1rem">per qty</span>)</th>
                  <th scope="col" style="width:11%" class="text-dark bg-light">
                    Quantity
                  </th>
                  <th scope="col" style="width:11%" class="text-dark bg-light">
                    Subtotal
                  </th>

                  <th scope="col" style="width:11%" class="text-dark bg-light">
                    Materials
                  </th>
                  <th scope="col" style="width:11%" class="text-dark bg-light">
                    Tooling
                  </th>
                  <th scope="col" style="width:12%" class="text-dark bg-light">
                    One Time Fees
                  </th>
                  <th scope="col" style="width:11%" class="text-dark bg-light">
                    Out. Process
                  </th>
                  <th scope="col" style="width:11%" class="text-dark bg-light">
                    Total
                  </th>
                </tr>

                <tr>
                  <!-- <td>$<input :disabled="quoteData.customerResponse == 'approve'" type="number" class="quoteInput form-control" placeholder="Labor" v-model="subItem.labor" /></td> -->

                  <td>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text prependIcon">$</span>
                      </div>
                      <input :disabled="quoteData.customerResponse == 'approve'" type="number" class="quoteInput form-control" v-model="subItem.labor" />
                    </div>
                  </td>

                  <td>
                    <input :disabled="quoteData.customerResponse == 'approve'" type="number" class="quoteInput form-control" v-model="subItem.hours" />
                  </td>

                  <td>
                    <input :disabled="quoteData.customerResponse == 'approve'" type="number" class="quoteInput form-control" v-model="subItem.quantity" />
                  </td>

                  <td>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text prependIcon">$</span>
                      </div>
                      <input disabled type="number" class="quoteInput form-control" v-model="subItem.sub_total" />
                    </div>
                  </td>

                  <td>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text prependIcon">$</span>
                      </div>
                      <input :disabled="quoteData.customerResponse == 'approve'" type="number" class="quoteInput form-control" v-model="subItem.materials" />
                    </div>
                  </td>
                  <td>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text prependIcon">$</span>
                      </div>
                      <input :disabled="quoteData.customerResponse == 'approve'" type="number" class="quoteInput form-control" v-model="subItem.tooling" />
                    </div>
                  </td>
                  <td>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text prependIcon">$</span>
                      </div>
                      <input :disabled="quoteData.customerResponse == 'approve'" type="number" class="quoteInput form-control" v-model="subItem.one_time" />
                    </div>
                  </td>

                  <td>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text prependIcon">$</span>
                      </div>
                      <input :disabled="quoteData.customerResponse == 'approve'" type="number" class="quoteInput form-control" v-model="subItem.o_process" />
                    </div>
                  </td>
                  <td>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text prependIcon">$</span>
                      </div>
                      <input :disabled="quoteData.customerResponse == 'approve'" type="number" class="quoteInput form-control" v-model="subItem.total" />
                    </div>
                  </td>
                </tr>

                <tr style="height: 100px;">
                  <td colspan="1" class="text-left">
                    <b-button size="sm" @click="selectFile(index)" variant="primary">
                      Upload
                    </b-button>
                  </td>

                  <td colspan="5" class="text-left">
                    <span v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
                      <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
                    </span>
                    <b-img-lazy v-if="subItem.image" thumbnail fluid v-bind="mainProps" style="width: 120px; height: 80px;" ref="refPreviewEl" :src="subItem.image" />
                  </td>

                  <td colspan="3">
                    <div>
                      <strong>Delivery By</strong>
                      <div class="d-flex align-items-center">
                        <!-- Datepicker -->
                        <!-- <b-form-datepicker
                          :disabled="subItem.deliveryBy != null"
                          :start-weekday="1"
                          v-model="subItem.needByVariable"
                          :date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                          }"
                          :hide-header="true"
                          local="en"
                          class="mb-2 mr-1"
                          today-variant="danger"
                        /> -->

                        <flat-pickr
                          placeholder="Select Quote Date"
                          :disabled="subItem.deliveryBy != null"
                          :style="subItem.deliveryBy != null ? '' : 'background-color:white'"
                          v-model="subItem.needByVariable"
                          style="cursor:pointer"
                          class="pl-1 form-control"
                          :config="{ dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1 } }"
                        />

                        <!-- Buttons -->
                        <b-button v-if="subItem.deliveryBy == null" size="sm" variant="success" class="btn-tour-skip mr-1" @click="confirmDelivery(subItem)">
                          <feather-icon icon="CheckIcon" size="21" />
                        </b-button>
                        <b-button size="sm" variant="outline-secondary" class="btn-tour-skip" @click="editDelivery(subItem)">
                          <feather-icon icon="EditIcon" size="21" />
                        </b-button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="mt-2 container">
          <b-col cols="12" md="8"> </b-col>
          <b-col cols="6" md="2" class="text-right"><strong>Quote Total: </strong> </b-col>
          <b-col cols="6" md="2">
            <vue-number style="border: none;" class="text-right mr-2" disabled :value="calculatedTotal" v-bind="money"> </vue-number>
          </b-col>
        </b-row>

        <app-collapse accordion type="margin">
          <app-collapse-item title="Quote Notes" class="bg-white">
            <quill-editor id="quil-content" :disabled="quoteData.customerResponse == 'approve'" v-model="quoteData.note" :options="editorOption" />
            <div id="quill-toolbar" class="d-flex justify-content-end">
              <button class="ql-bold"></button>
            </div>
          </app-collapse-item>

          <app-collapse-item title="Terms & Conditions" class="bg-white">
            <quill-editor id="quil-content" ref="quillEditor" :disabled="quoteData.customerResponse == 'approve'" v-model="quoteData.terms" :options="editorOption2" />
            <div id="quill-toolbar2" class="d-flex justify-content-end">
              <button class="ql-bold"></button>
            </div>
          </app-collapse-item>
        </app-collapse>

        <!-- <b-form class="mt-1">
          <b-row>
            <b-col cols="12">
              <label>Quote Notes</label>
              <quill-editor id="quil-content" :disabled="quoteData.customerResponse == 'approve'" v-model="quoteData.note" :options="editorOption" />
              <div id="quill-toolbar" class="d-flex justify-content-end">
                <button class="ql-bold"></button>
              </div>
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <b-col cols="12">
              <label>Terms & Conditions</label>
              <quill-editor id="quil-content" ref="quillEditor" :disabled="quoteData.customerResponse == 'approve'" v-model="quoteData.terms" :options="editorOption" />
              <div id="quill-toolbar" class="d-flex justify-content-end">
                <button class="ql-bold"></button>
              </div>
            </b-col>
          </b-row>
        </b-form> -->

        <b-row class="mt-2 mb-5">
          <div class="w-100 container">
            <!-- <b-button v-if="quoteData.projectId != null && quoteData.customerResponse != 'approve'" @click="saveQuote('saveSubmit')" type="submit" variant="primary" class="float-right">
              Save & Submit
            </b-button> -->

            <b-button v-if="quoteData.projectId != null && quoteData.customerResponse != 'approve'" @click="saveQuote('save')" type="submit" variant="primary" class="float-right mr-25">
              Save
            </b-button>
          </div>
        </b-row>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import { BOverlay, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormRating } from 'bootstrap-vue';
import store from '@/store';
import { onUnmounted } from '@vue/composition-api';
import projectStoreModule from './projectStoreModule';
import vSelect from 'vue-select';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { BFormDatepicker } from 'bootstrap-vue';
import { quillEditor } from 'vue-quill-editor';
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
import ItemCropper from '@core/components/image-cropper/ItemCropper.vue';
import { ref } from '@vue/composition-api';
import axiosIns from '@/libs/axios';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import flatPickr from 'vue-flatpickr-component';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    ToastificationContent,
    BFormDatepicker,
    quillEditor,
    ItemCropper,
    AppCollapse,
    AppCollapseItem,
    flatPickr,
  },

  setup() {
    const QUOTE_APP_STORE_MODULE_NAME = 'quote';

    // Register module
    if (!store.hasModule(QUOTE_APP_STORE_MODULE_NAME)) store.registerModule(QUOTE_APP_STORE_MODULE_NAME, projectStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(QUOTE_APP_STORE_MODULE_NAME)) store.unregisterModule(QUOTE_APP_STORE_MODULE_NAME);
    });
    return {};
  },

  data() {
    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Write your message',
    };

    const editorOption2 = {
      modules: {
        toolbar: '#quill-toolbar2',
      },
      placeholder: 'Write your message',
    };

    return {
      imageUrl: store.state.app.baseURL + 'images/none.jpg',
      tempImage: null,
      profileURL: store.state.app.baseURL + 'images/',
      selectedImage: null,
      croppedImage: null,
      percentCompleted: 0,
      profileFile: null,
      selectedSubItem: null,

      mainProps: {
        blank: true,
        blankColor: '#777',
        width: 75,
        height: 75,
        class: 'm1',
      },

      editorOption,
      editorOption2,
      loading: true,
      baseURL: store.state.app.baseURL,
      dateDefault: null,
      tableData: [],
      projectValues: [],
      mailedLog: '',

      quoteData: {
        mailSent: 0,
        id: null,
        projectId: parseInt(router.currentRoute.params.id),
        tableData: [],
        date: new Date().toISOString().split('T')[0],
        expiryDate: new Date().toISOString().split('T')[0],

        total: 0,
        terms: null,
        note: null,
      },
      process: null,
      nextId: 2,
      tableFields: [
        {
          key: 'item',
          label: 'Part',
          class: 'text-right',
          thStyle: 'width:25%',
        },
        {
          key: 'labor',
          label: 'Labor',
          class: 'text-right',
          thStyle: 'width:10%',
        },
        {
          key: 'hours',
          label: 'Hours',
          class: 'text-right',
          thStyle: 'width:5%',
        },

        {
          key: 'materials',
          label: 'Materials',
          class: 'text-right',
          thStyle: 'width:10%',
        },
        {
          key: 'tooling',
          label: 'Tooling',
          class: 'text-right',
          thStyle: 'width:10%',
        },
        {
          key: 'o_process',
          label: 'Outside Process',
          class: 'text-right',
          thStyle: 'width:10%',
        },
        {
          key: 'sub_total',
          label: 'Subtotal',
          class: 'text-right',
          thStyle: 'width:10%',
        },

        {
          key: 'quantity',
          label: 'Quantity',
          class: 'text-right',
          thStyle: 'width:10%',
        },
        {
          key: 'total',
          label: 'total',
          class: 'text-right',
          thStyle: 'width:10%',
        },
      ],

      percent: {
        decimal: '.',
        separator: ',',
        suffix: '%',
        precision: 2,
        masked: false,
        min: 0,
        max: 100,
        minimumFractionDigits: 2,
      },

      money: {
        decimal: '.',
        separator: ',',
        prefix: '$',
        precision: 2,
        masked: false,
        minimumFractionDigits: 2,
      },

      quantity: {
        precision: 2,
        masked: false,
        reverseFill: false,
        prefill: false,
        min: 0,
      },
    };
  },

  watch: {
    projectValues: {
      handler: function(newVal, oldVal) {
        newVal.forEach((subItem) => {
          subItem.sub_total = Number(subItem.labor) * Number(subItem.hours) * Number(subItem.quantity);
          subItem.total = subItem.sub_total + Number(subItem.o_process) + Number(subItem.materials) + Number(subItem.tooling) + Number(subItem.one_time);
        });
      },
      deep: true,
    },
  },

  methods: {
    confirmDelivery(subItem) {
      subItem.deliveryBy = subItem.needByVariable;
    },
    editDelivery(subItem) {
      subItem.deliveryBy = null;
    },

    selectFile(index) {
      this.$refs[`refInputEl_${index}`][0].value = null;
      this.selectedSubItem = index;
      this.$refs[`refInputEl_${index}`][0].click();
    },

    imageSelected(event) {
      const file = event.target.files ? event.target.files[0] : null;

      if (file != null) {
        this.getBase64(file).then((data) => {
          this.selectedImage = data;
        });
      }
    },
    imageCropped(croppedImage) {
      if (croppedImage != null) {
        // this.$emit('update:formShow', true);
        let formData = new FormData();
        formData.append('file', croppedImage);
        const config = {
          onUploadProgress: function(progressEvent) {
            this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          }.bind(this),
        };
        axiosIns
          .post('cropProfilePhoto', formData, config)
          .then((response) => {
            this.imageUrl = this.profileURL + response.data;
            this.projectValues.find((e, index) => index == this.selectedSubItem).image = this.imageUrl;
            this.tempImage = null;
            this.selectedImage = null;
            this.$emit('update:formShow', false);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Upload Successful',
                text: '✔️ Image Cropped Successfully',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
          })
          .catch((error) => {
            console.log(error);
            this.$emit('update:formShow', false);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Upload Not Successful',
                text: '⛔ Image Upload Not Successful',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            });
          });
      } else {
        this.tempImage = null;
        this.selectedImage = null;
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    saveQuote(_type) {
      this.loading = true;

      this.quoteData.total = this.calculatedTotal;
      this.quoteData.tableData = this.projectValues;

      // let deliveryCheck = false;
      let deliveryCheck = false;

      this.projectValues.forEach((element) => {
        // if (element.deliveryBy == null) {
        //   deliveryCheck = true;
        // }
        if(element.deliveryBy == null){
          element.deliveryBy == element.needByVariable;
        }
      });
      if (deliveryCheck) {
        this.$emit('update:loading', false);
        this.$swal({
          title: 'Error!',
          text: 'Please confirm Delivery By dates.',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        });
        this.loading = false;
      } else {
        store
          .dispatch('quote/saveFormQuote', { data: this.quoteData, type: _type })
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Quote has been saved successfully',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });

            router.push({
              name: 'project-preview',
              params: { id: this.quoteData.projectId },
            });
          })
          .catch((error) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occurred',
                text: 'Please try again later or contact support.',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            });
          });
      }
    },

    mailQuote() {
      this.loading = true;
      store
        .dispatch('quote/sendQuote', this.quoteData)
        .then((response) => {
          if (response.data == 'ok') {
            this.quoteData.mailSent = 1;
            this.mailedLog = 'Quote has been emailed to the customer just now by you.';
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Quote has been sent successfully',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
            this.loading = false;
          } else {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occurred',
                text: 'Please try again later or contact support.',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          });
        });
    },

    downloadQuote() {
      this.loading = true;
      store
        .dispatch('quote/downloadQuote', this.quoteData)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          let fileName = 'Quote_' + this.quoteData.id + '.pdf';
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();

          this.simulateFileDownload();
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          });
        });
    },

    simulateFileDownload() {
      setTimeout(() => {
        this.loading = false;
        this.$swal.fire('Download Completed!', 'Your file has been successfully downloaded.', 'success');
      }, 200);
    },

    customerStatus(customerResponse) {
      this.loading = true;
      store
        .dispatch('quote/customerStatus', {
          id: this.quoteData.id,
          response: customerResponse,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Response has been registered successfully',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });

          this.getForms();
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          });
        });
    },

    getForms() {
      this.loading = true;
      store
        .dispatch('quote/getFormQuoteById', {
          id: router.currentRoute.params.id,
        })
        .then((res) => {
          this.process = res.data.form.process;
          let laborPrice = 0;
          let noteAddition = '';

          if (res.data.form.type == 'internal') {
            laborPrice = res.data.settings.internalRate;
            noteAddition =
              '<p>- As an internal customer, the work is prioritized in terms of the delivery date.</p>' +
              '<p>- The hourly rate for internal customers is $' +
              res.data.settings.internalRate +
              ' whereas this rate is $' +
              res.data.settings.externalRate +
              ' for the external customers. </p>' +
              '<p>- The price stated on the quote is an estimate. If the work exceeds the quoted work hours, internal customers will be charged for the additional hours.</p>' +
              '<p>- Internal customers are required to pay any external fees for the production of the materials. </p>' +
              '<p>- The quote does not include inserts or assembly.</p>';
          } else {
            laborPrice = res.data.settings.externalRate;
            noteAddition = '<p>- The price stated on the quote is the final price for the order.</p>' + '<p>- There will not be any additional costs or incurring expenses.</p>' + '<p>- The quote does not include inserts or assembly.</p>';
          }

          this.projectValues = [];
          if (res.data.form.process == 'draft') {
            res.data.form.projectValues.forEach((project) => {
              this.projectValues.push({
                item: project.partNumber + '-' + project.partName,
                labor: laborPrice,
                hours: 0,
                materials: 0,
                tooling: 0,
                o_process: 0,
                subTotal: 0,
                quantity: project.qty ?? 1,
                total: 0,
                material: project.materialName,
                revision: project.revision,
                ovProcess: project.ovProcess,
                partNumber: project.partNumber,
                one_time: 0,
                x: project.x,
                y: project.y,
                z: project.z,
                needBy: project.needBy ?? new Date().toISOString().split('T')[0],
                deliveryBy: null,
                needByVariable: project.needBy ?? new Date().toISOString().split('T')[0],
                image: null,
              });
            });
            this.quoteData.terms = res.data.settings.terms;

            this.quoteData.note = res.data.settings.note + noteAddition;
          } else {
            this.quoteData.customerResponse = res.data.form.form_quote.customerResponse;
            this.quoteData.terms = res.data.form.form_quote.terms;
            this.quoteData.note = res.data.form.form_quote.note;
            this.quoteData.id = res.data.form.form_quote.id;
            res.data.form.form_quote.tableData.forEach((project) => {
              this.projectValues.push(project);
            });
          }

          if (res.data.form.form_quote && Array.isArray(res.data.form.form_quote.tableData)) {
            res.data.form.form_quote.tableData.forEach((element, index) => {
              if (this.projectValues[index]) {
                this.projectValues[index].parts = element.parts;
                this.quoteData.date = res.data.form.form_quote.date;
                this.quoteData.expiryDate = res.data.form.form_quote.expiryDate;
                this.quoteData.mailSent = res.data.form.form_quote.mailSent;
              }
            });
          } else {
            const currentDate = new Date();
            const expiryDate = new Date(currentDate);
            expiryDate.setDate(currentDate.getDate() + 14);

            this.quoteData.expiryDate = expiryDate.toISOString().split('T')[0];
          }
          this.quoteData.mailDate = res.data.form.mailDate;
          this.quoteData.mailUser = res.data.form.mailUser;

          this.mailedLog = 'The quote has been emailed to the customer lastly on ' + this.quoteData.mailDate + '  by  ' + this.quoteData.mailUser + '.';

          this.loading = false;

          this.$nextTick(() => {
            document.activeElement.blur();
            window.scrollTo(0, 0);
          });

          if (!res.data.form.id) {
            this.$router.push({ name: 'error-404' });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },
  },

  created() {
    this.getForms();
  },

  computed: {
    calculatedTotal() {
      let amount = 0;

      this.projectValues.forEach((element) => {
        amount += element.total;
      });

      return amount.toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.title {
  font-weight: 600;
}

.border {
  padding: 0.5rem;
}

.bigTitle {
  color: #a51c30;

  font-size: 1.5rem;
}
// text-center mt-5 p-1 bg-light-secondary
.add-new-line-button {
  // text-align: center;
  margin-top: 2rem;
  // height: 3rem;
  // border: 1px solid #a51c3050;
  // border-style: dashed;
  // cursor: pointer;

  outline: none;
  display: inline-flex;

  line-height: 1.5;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  -webkit-box-pack: center;
  justify-content: center;
  vertical-align: middle;
  -webkit-box-align: center;
  align-items: center;
  border: 1px dashed #a51c3080;
  height: 3rem;
  padding: 0px 1.5rem;
  width: 100%;
  background: rgb(247, 250, 255);
  color: #a51c30;
}

.add-new-line-button:hover {
  background: rgb(243, 245, 247);
  color: rgb(97, 113, 131);
}

.quoteInput {
  border: none;
  background-color: #ffffff;
  border-bottom: 1px solid lightgray;
}

.form-control:focus {
  border-color: #a51c30 !important;
}

.form-control {
  padding: 0px;
}

#quil-content ::v-deep {
  > .ql-container {
    .ql-editor {
      height: 300px !important;
    }
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
    border-top-right-radius: 0rem;
    border-top-left-radius: 0rem;
    background-color: white;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  + #comment-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.prependIcon {
  border: none;
  font-size: 1.2rem;
  margin-right: 0px;
  padding-right: 3px;
  // margin-top: 2px;

  border-bottom: 1px solid lightgray;
}

.uploadButton {
  position: absolute;
  top: -8px;
  right: 85px;
  margin: 2px;
  padding: 3px;
  border-radius: 6px;
  color: #fff;
  background-color: #ff0000;
  cursor: pointer;
  opacity: 0.5;
}
.uploadButton:hover {
  opacity: 1;
}
.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
./projectStoreModule
