import { render, staticRenderFns } from "./GenerateQuote.vue?vue&type=template&id=66f70e01&scoped=true&"
import script from "./GenerateQuote.vue?vue&type=script&lang=js&"
export * from "./GenerateQuote.vue?vue&type=script&lang=js&"
import style0 from "./GenerateQuote.vue?vue&type=style&index=0&id=66f70e01&lang=scss&scoped=true&"
import style1 from "./GenerateQuote.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66f70e01",
  null
  
)

export default component.exports